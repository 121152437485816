import React, { useEffect } from 'react';
import { Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { appRoutes as routes } from './route';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {routes.map((route, index) => {
          return !route.children ? (
            <route.route
              key={index}
              path={route.path}
              roles={route.roles}
              exact={route.exact}
              element={route.component}
            ></route.route>
          ) : null;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
