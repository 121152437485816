import React from 'react';
import { Route } from 'react-router-dom';

const Home = React.lazy(() => import('../modules/pages/Home'));
const Login = React.lazy(() => import('../modules/pages/Auth/Login'));
const Register = React.lazy(() => import('../modules/pages/Auth/Register'));
const TicketBooking = React.lazy(() =>
  import('../modules/pages/TicketBooking')
);

const Tour = React.lazy(() => import('../modules/pages/Tour'));
const Packages = React.lazy(() => import('../modules/pages/Tour/Packages'));

const VisaProcessing = React.lazy(() =>
  import('../modules/pages/VisaProcessing')
);
const Hajj = React.lazy(() => import('../modules/pages/Hajj'));
const Contact = React.lazy(() => import('../modules/pages/Contact'));
const About = React.lazy(() => import('../modules/pages/About'));

const home = {
  path: '/',
  exact: true,
  name: 'Home component',
  component: <Home />,
  roles: ['User'],
  route: Route
};

const ticketBooking = {
  path: '/ticket/booking',
  exact: true,
  name: 'Ticket Booking component',
  component: <TicketBooking />,
  roles: ['User'],
  route: Route
};

const tour = {
  path: '/tour/planning',
  exact: true,
  name: 'Tour component',
  component: <Tour />,
  roles: ['User'],
  route: Route
};

const packages = {
  path: '/tour/planning/:country/packages',
  exact: true,
  name: 'Tour component',
  component: <Packages />,
  roles: ['User'],
  route: Route
};
const visaProcessing = {
  path: '/visa/processing',
  exact: true,
  name: 'Visa Processing component',
  component: <VisaProcessing />,
  roles: ['User'],
  route: Route
};

const hajj = {
  path: '/hajj',
  exact: true,
  name: 'Hajj component',
  component: <Hajj />,
  roles: ['User'],
  route: Route
};

const contact = {
  path: '/contact',
  exact: true,
  name: 'Contact component',
  component: <Contact />,
  roles: ['User'],
  route: Route
};

const about = {
  path: '/about',
  exact: true,
  name: 'About component',
  component: <About />,
  roles: ['User'],
  route: Route
};
const login = {
  path: '/auth/login',
  exact: true,
  name: 'Login component',
  component: <Login />,
  roles: ['User'],
  route: Route
};

const register = {
  path: '/auth/register',
  exact: true,
  name: 'Register component',
  component: <Register />,
  roles: ['User'],
  route: Route
};

export const appRoutes = [
  home,
  ticketBooking,
  login,
  register,
  visaProcessing,
  tour,
  packages,
  hajj,
  contact,
  about
];
