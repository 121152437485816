import { Suspense, useEffect } from 'react';
import './App.css';
import { Loader } from './modules/shared/components/Tailwind/Loader/Loader';

import Router from './routes';
import { useLocation } from 'react-router-dom';

function App() {
  return (
    <Suspense
      fallback={<Loader height={'h-[100vh]'} message="Flying you in" />}
    >
      <Router />
    </Suspense>
  );
}

export default App;
