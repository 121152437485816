import React from 'react';
import './Loader.css';
import CircularProgressFeedback from '../../Chakra/Feedback/CircularProgress';
import LogoIcon from '../../../icons/LogoIcon';
export const Loader = ({ message, height }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center ${
        height ? height : 'h-[100%]'
      }`}
    >
      {/* <CircularProgressFeedback /> */}
      <div className="h-[40px] w-[40px] fade-in-out">
        <LogoIcon />
      </div>
      <h1 className="font-medium text-dark mt-4 text-center">
        {message && message}
      </h1>
    </div>
  );
};
