import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const colors = {
  brand: {
    100: '#4269E2',
    200: '#c2b5e7',
    300: '#9984d7',
    400: '#856bce',
    500: '#4269E2',
    600: '#204BD0'
  },
  secondary: '#F5B03C',
  green: '#75E242',
  red: '#E24242',
  dark: '#0E2058',
  primaryLight: '#F0F4FF'
};

const theme = extendTheme({
  colors,
  components: {
    Alert: {
      variants: {
        toast: (P) => {
          return {
            container: {
              ...P.theme.components.Alert.variants.solid(P).container,
              bottom: '64px'
            }
          };
        }
      }
    }
  }
});
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
