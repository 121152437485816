import React from 'react';

const LogoIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 328 329" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M246.203 236.724C217.371 228.711 190.819 213.155 169.624 191.929C126.984 146.9 57.2771 132.39 0.189968 156.162C-0.575359 172.187 0.970914 188.399 4.8444 203.815C147.133 178.746 150.038 283.971 258.98 297.872C279.441 283.362 296.153 264.541 308.086 242.534C287.328 244.299 266.149 242.3 246.203 236.724Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M251.49 182.323C199.729 171.781 172.49 123.971 126.539 103.588C91.1778 87.7664 50.0845 86.7668 13.4894 98.9808C7.22618 113.35 3.13402 128.532 1.22852 144.073C61.3457 121.3 133.052 137.591 177.379 184.198C212.552 219.809 264.22 237.442 313.825 230.945C320.073 216.919 324.353 202.081 326.43 186.837C301.596 190.758 275.59 189.196 251.49 182.323V182.323Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M241.142 305.804C144.195 284.328 135.308 191.599 7.82617 214.356C8.63836 216.87 9.4974 219.354 10.4189 221.806C42.4377 314.535 160.782 357.128 245.265 306.632C243.89 306.367 242.5 306.117 241.126 305.804H241.142Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M137.602 15.5134C194.533 28.0553 217.821 77.5829 268.488 96.7473C282.436 89.4533 301.663 90.5935 314.627 99.262C282.639 21.4954 187.317 -20.3633 108.301 9.87493C118.234 10.9214 128.012 12.8113 137.602 15.5134V15.5134Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M24.2249 84.6101C59.5393 75.7386 97.6963 78.878 130.918 93.5754C176.884 112.927 204.514 161.955 255.479 172.092C236.955 154.63 238.157 120.284 257.665 104.087C217.868 88.4992 194.393 47.7495 154.018 32.8647C132.558 23.7432 109.332 19.3231 85.9508 19.8853C61.3042 33.2239 40.4529 52.6695 25.5369 76.254C23.5533 79.3466 21.6946 82.5172 19.9453 85.7347C21.3823 85.3286 22.788 84.9537 24.2249 84.6101V84.6101Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M303.798 105.836C249.46 85.7343 231.17 172.044 289.319 175.465C328.07 175.98 339.409 120.736 303.798 105.836Z"
        fill={color ? color : '#4269E2'}
      />
      <path
        d="M314.391 177.325C318.811 176.903 323.231 176.341 327.589 175.56C327.87 171.671 327.995 167.438 327.995 163.44C324.512 168.985 319.857 173.717 314.391 177.325V177.325Z"
        fill={color ? color : '#4269E2'}
      />
    </svg>
  );
};

export default LogoIcon;
